body {
  background-color: #dfe7f4;
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

/* General padding for sections to avoid content going under the navbar */
.container-md,
.container-sm,
section {
  padding-top: 2vh;
}

/* Adjust padding for mobile devices */
@media (max-width: 768px) {
  .container-md,
  .container-sm,
  section {
    padding-top: 0vh; /* More top padding for mobile devices */
  }
}

span {
  color: #4034cf;
}

.image-fluid {
  height: 80vh !important;
}

#navbarNav li a {
  text-decoration: none;
  font-size: 18px;
  font-weight: 600;
  color: #000;
  transition: 0.3s ease;
}

#navbarNav li a:hover {
  color: #4034cf;
}

.navbar {
  /* margin-bottom: 60px; Adds space below the navbar */
}

